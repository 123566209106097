import { ApiService } from '@/services/admin/api.service'

export const LoyaltyAdvantagesModule = {
  namespaced: true,
  state: {
    loyaltyAdvantage: null,
    loyaltyAdvantages: [],
    total: 0,
    page: 0,
    perPage: 25
  },
  getters: {
    getLoyaltyAdvantage: (state) => state.loyaltyAdvantage,
    getLoyaltyAdvantages: (state) => state.loyaltyAdvantages
  },
  mutations: {
    setLoyaltyAdvantages (state, payload) {
      state.loyaltyAdvantages = payload.points
      state.total = payload.total
    },
    setLoyaltyAdvantage (state, payload) {
      state.loyaltyAdvantage = payload
    },
    setPagination (state, payload) {
      state.page = payload.page !== undefined ? payload.page : state.page
      state.perPage = payload.perPage !== undefined ? payload.perPage : state.perPage
    }
  },
  actions: {
    async fetchLoyaltyAdvantages ({ state, commit }, payload = {}) {
      commit('setPagination', payload)
      await ApiService.fetchLoyaltyAdvantages(state.page, state.perPage, payload.search).then(res => {
        commit('setLoyaltyAdvantages', res.data)
      })
    },
    async fetchLoyaltyAdvantage ({ state, commit }, payload = {}) {
      await ApiService.fetchLoyaltyAdvantage(payload).then(res => {
        commit('setLoyaltyAdvantage', res.data)
      })
    }
  }
}
