import { ApiService } from '@/services/iot/api.service'

export const HomesModule = {
  namespaced: true,
  state: {
    home: null,
    homes: []
  },
  getters: {
    getHome: (state) => state.home,
    getHomes: (state) => state.homes,
    getTotalLocations: (state) => state.homes.length,
    getTotalDevices (state) {
      let total = 0
      state.homes.forEach(home => home.home.rooms.forEach(room => { total += room.devices.length }))
      return total
    },
    getTotalWarnings: (state) => {
      let total = 0
      state.homes.forEach(home => {
        total += home.home.warnings
      })
      return total
    }
  },
  mutations: {
    setHome (state, payload) {
      state.home = payload
    },
    setHomes (state, payload) {
      state.homes = payload
    },
    createHome (state, payload) {
      state.homes.push(payload)
    },
    updateHome (state, payload) {
      var home = state.homes.find(h => h.home.id === payload.home.id)
      home.home = payload.home
    }
  },
  actions: {
    async fetchHome ({ commit }, payload = {}) {
      await ApiService.getHome(payload.homeId).then(res => {
        commit('setHome', res.data)
      })
    },
    async fetchHomes ({ commit }) {
      await ApiService.getHomes().then(res => {
        commit('setHomes', res.data)
      })
    },
    async createHome ({ commit }, payload = {}) {
      await ApiService.createHome(payload.home).then(res => {
        commit('createHome', res.data)
      })
    },
    async updateHome ({ commit }, payload = {}) {
      await ApiService.updateHome(payload.homeId, payload.home).then(res => {
        commit('updateHome', res.data)
      })
    }
  }
}
