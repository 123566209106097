import { DefaultService } from '../default'

class ApiService extends DefaultService {
  constructor () {
    super(process.env.VUE_APP_API)
  }

  getAxiosInstance () {
    return this.axios
  }

  getFile (fileId) {
    return this.axios.get('/file', {
      params: {
        file_id: fileId
      }
    })
  }

  getFiles (fileIds) {
    return this.axios.get('/files', {
      params: {
        file_ids: fileIds
      }
    })
  }

  deleteFile (fileId) {
    return this.axios.delete('/file', {
      params: {
        file_id: fileId
      }
    })
  }

  uploadFiles (files) {
    return this.axios({
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      method: 'post',
      data: files,
      url: '/files'
    })
  }

  /** Appointments **/

  fetchReservedSlots (startDate, endDate, honorDeadlines = true, showroom = false) {
    return this.axios.get('/appointment/slots', {
      params: {
        date_start: startDate,
        date_end: endDate,
        honor_deadlines: honorDeadlines,
        showroom: showroom
      }
    })
  }

  fetchAppointments (slot) {
    return this.axios.get('/appointments', {
      params: {
        slot: slot
      }
    })
  }

  fetchAppointmentsOverview (data) {
    return this.axios.get('/appointments/all', {
      params: data
    })
  }

  exportAppointmentsOverview (data) {
    return this.axios.get('/appointments/all/export', {
      params: data
    })
  }

  saveAppointment (appointment, method, showroom = false) {
    return this.axios({
      data: appointment,
      url: method === 'post' ? '/appointment/novalidate' : '/appointment/' + appointment.id + '/novalidate',
      method: method,
      params: { showroom: true }
    })
  }

  saveAppointmentPublic (appointment) {
    return this.axios.post('/appointment', appointment)
  }

  deleteAppointment (id) {
    return this.axios.delete('/appointment/' + id)
  }

  fetchSlotConfig (time) {
    return this.axios.get('/appointment/slots/config', {
      params: {
        slot: time
      }
    })
  }

  addSlotConfig (time, type) {
    return this.axios.post('/appointment/slots/config', {
      slot: time,
      type: type
    })
  }

  deleteSlotConfig (slotId) {
    return this.axios.delete('/appointment/slots/config', {
      data: {
        id: slotId
      }
    })
  }

  daySlotConfig (day, type, method) {
    return this.axios({
      data: {
        day: day,
        type: type
      },
      url: '/appointment/slots/config/day',
      method: method
    })
  }

  /** Roles and permissions **/

  fetchRoles (page, perPage) {
    return this.axios.get('/roles', {
      params: {
        page: page,
        per_page: perPage
      }
    })
  }

  deleteRole (id) {
    return this.axios.delete('/role', {
      data: { id: id }
    })
  }

  saveRole (data) {
    const method = data.id ? 'patch' : 'post'
    return this.axios({
      url: '/role',
      method: method,
      data: data
    })
  }

  fetchPermissions () {
    return this.axios.get('/permissions')
  }

  /** Users **/

  fetchUsers (page, perPage, search, sortBy, sortDir, withoutDealers) {
    return this.axios.get('/users', {
      params: {
        page: page,
        per_page: perPage,
        search: search,
        order_by: sortBy,
        order_dir: sortDir,
        without_dealers: withoutDealers
      }
    })
  }

  savePassword (data) {
    return this.axios.post('/user/password', data)
  }

  exportAllUsers (withoutDealers = false) {
    return this.axios.get('/users/all/export', {
      params: {
        without_dealers: withoutDealers
      }
    })
  }

  fetchUser (id) {
    return this.axios.get('/user/' + id)
  }

  saveUser (data) {
    const method = data.id ? 'patch' : 'post'
    const url = data.id ? '/user/' + data.id + '/novalidate' : '/user'
    return this.axios(url, {
      data: data,
      method: method
    })
  }

  deleteUser (id) {
    return this.axios.delete('/user/' + id)
  }

  getActiveUsersTotal () {
    return this.axios.get('/users/stats/active')
  }

  fetchOrders (dealerId) {
    return this.axios.get('/orders/' + dealerId)
  }

  fetchUserDealers (userId) {
    return this.axios.get('/user/' + userId + '/dealers')
  }

  /** Auth **/

  login (data) {
    return this.axios.post('/login', data.formData, {
      params: {
        redirectUrl: data.redirectUrl
      }
    })
  }

  refresh () {
    return this.axios.post('/refresh')
  }

  requestPasswordReset (email) {
    return this.axios.post('/user/password-reset/request', { email: email })
  }

  resetPasswordCheck (hash, password, passwordRepeat) {
    return this.axios.post('/user/password-reset/claim-check', {
      hash: hash
    })
  }

  resetPassword (hash, password, passwordRepeat) {
    return this.axios.post('/user/password-reset/claim', {
      hash: hash,
      password: password,
      password_repeat: passwordRepeat
    })
  }

  /** Dealers **/

  fetchDealers (page, perPage, search, orderBy, orderDir, withoutUsers = false) {
    return this.axios.get(withoutUsers ? '/dealers/without-users' : '/dealers', {
      params: {
        order_by: orderBy,
        order_dir: orderDir,
        page: page,
        per_page: perPage,
        search: search
      }
    })
  }

  exportAllDealers () {
    return this.axios.get('/dealers/all/export')
  }

  fetchDealer (id) {
    return this.axios.get('/dealer/' + id)
  }

  fetchDealerSingleUsers (id) {
    return this.axios.get('/dealer/ ' + id + '/single-users')
  }

  fetchDealersBenchmark () {
    return this.axios.get('/dealers/benchmark')
  }

  saveDealer (data) {
    return data.id ? this.axios.patch('/dealer/' + data.id, data) : this.axios.post('/dealer', data)
  }

  saveDealerInformation (dealerId, data) {
    return this.axios.patch('/dealer/' + dealerId + '/information', data)
  }

  deleteDealer (id) {
    return this.axios.delete('/dealer/' + id)
  }

  importDealers (fileId, confirmedDealers) {
    return this.axios.post('/dealers/import/confirm', {
      file_id: fileId,
      ids_to_add: confirmedDealers
    })
  }

  createDealerVacations (dealerId, data) {
    return this.axios.post('/dealer/' + dealerId + '/vacation', data)
  }

  saveDealerVacation (dealerId, vacationId, data) {
    return this.axios.patch('/dealer/' + dealerId + '/vacation/' + vacationId, data)
  }

  deleteDealerVacation (dealerId, vacationId) {
    return this.axios.delete('/dealer/' + dealerId + '/vacation/' + vacationId)
  }

  exportDealerVacations () {
    return this.axios.get('/dealers/vacations')
  }

  fetchDealerLoyalty (dealerId) {
    return this.axios.get('/dealer/' + dealerId + '/loyalty')
  }

  patchDealerLoyalty (dealerId, data) {
    return this.axios.patch('/dealer/' + dealerId + '/loyalty', data)
  }

  /** Menu Items **/

  fetchMenuItem (id) {
    return this.axios.get('/menu/item/' + id)
  }

  saveMenuItem (data) {
    const method = data.id ? 'patch' : 'post'
    return this.axios({
      url: method === 'patch' ? '/menu/item/' + data.id : '/menu/item',
      data: data,
      method: method
    })
  }

  saveMenuItems (data) {
    return this.axios.post('/menu/items', data)
  }

  fetchMenuItems (params) {
    return this.axios.get('/menu/items', {
      params: params
    })
  }

  deleteMenuItem (id) {
    return this.axios.delete('/menu/item/' + id)
  }

  /** News items **/

  fetchNewsItems (page) {
    return this.axios.get('/news', {
      params: {
        page: page
      }
    })
  }

  fetchLatestHighlightedNewsItem () {
    return this.axios.get('/news-item/highlighted')
  }

  fetchLatestSubhighlightedNewsItem () {
    return this.axios.get('/news-item/subhighlighted')
  }

  fetchNewsItem (id) {
    return this.axios.get('/news-item/' + id)
  }

  saveNewsItem (data) {
    return this.axios({
      method: data.id ? 'patch' : 'post',
      url: '/news-item',
      data: data
    })
  }

  deleteNewsItem (id) {
    return this.axios.delete('/news-item/' + id)
  }

  /** Profile **/

  saveProfile (data) {
    return this.axios.patch('/profile/' + data.id, data)
  }

  /** Loyalty Upgrade **/

  fetchLoyaltyUpgrades (page, perPage) {
    return this.axios.get('/loyalty/upgrades', {
      params: {
        page: page,
        per_page: perPage
      }
    })
  }

  fetchLoyaltyUpgrade (id) {
    return this.axios.get('/loyalty/upgrade/' + id)
  }

  saveLoyaltyUpgrade (data) {
    return this.axios.patch('/loyalty/upgrade/' + data.id, data)
  }

  createLoyaltyUpgrade (data) {
    return this.axios.post('/loyalty/upgrade', data)
  }

  fetchUpgradePointsByDealerId (dealerId) {
    return this.axios.get('/loyalty/upgrades/' + dealerId)
  }

  /** Loyalty Advantages **/

  fetchLoyaltyAdvantages (page) {
    return this.axios.get('/loyalty/advantages', {
      params: {
        page: page
      }
    })
  }

  fetchLoyaltyAdvantagesByRating (rating) {
    return this.axios.get('/loyalty/advantages/' + rating)
  }

  fetchLoyaltyAdvantage (id) {
    return this.axios.get('/loyalty/advantage/' + id)
  }

  saveLoyaltyAdvantage (data) {
    return this.axios({
      method: data.id ? 'patch' : 'post',
      url: '/loyalty/advantage' + (data.id ? '/' + data.id : ''),
      data: data
    })
  }

  deleteLoyaltyAdvantage (id) {
    return this.axios.delete('/loyalty/advantage/' + id)
  }

  /** Quotes **/

  createQuoteDealerExtra (dealerId, data) {
    return this.axios.post('/quotes/dealer/' + dealerId + '/extra', data)
  }

  patchQuoteDealerExtra (dealerId, data) {
    return this.axios.patch('/quotes/dealer/' + dealerId + '/extra/' + data.id, data)
  }

  deleteQuoteDealerExtra (dealerId, id) {
    return this.axios.delete('/quotes/dealer/' + dealerId + '/extra/' + id)
  }

  fetchQuotes (dealerId) {
    return this.axios.get('/quotes/dealer/' + dealerId)
  }

  fetchQuote (id, hash = null) {
    if (hash) {
      return this.axios.get('/quote/' + id + '/hash/' + hash)
    }

    return this.axios.get('/quote/' + id)
  }

  fetchQuoteNumber (id) {
    return this.axios.get('/quote/' + id + '/number')
  }

  createQuote (data) {
    return this.axios.post('/quote', data)
  }

  patchQuote (id, data) {
    return this.axios.patch('/quote/' + id, data)
  }

  createQuoteSignature (data) {
    return this.axios.post('/quotes/signature', data)
  }

  createAddress (data) {
    return this.axios.post('/address', data)
  }

  patchAddress (id, data) {
    return this.axios.patch('/address/' + id, data)
  }

  createQuoteExtra (quoteId, data) {
    return this.axios.post('/quote/' + quoteId + '/extra', data)
  }

  patchQuoteExtra (quoteId, extraId, data) {
    return this.axios.patch('/quote/' + quoteId + '/extra/' + extraId, data)
  }

  deleteQuoteExtra (quoteId, extraId) {
    return this.axios.delete('/quote/' + quoteId + '/extra/' + extraId)
  }

  fetchQuoteStatuses () {
    return this.axios.get('/quotes/statuses')
  }

  createQuotePdfPy (quoteId, configuration) {
    return this.axios.post('/quote/' + quoteId + '/pdf/pyppeteer', configuration)
  }

  createQuotePdf (quoteId, configuration = {}) {
    return this.axios.post('/quote/' + quoteId + '/pdf/initiate', configuration)
  }

  checkQuotePdfStatus (quoteId, tempHash, pressgridId) {
    return this.axios.get('/quote/' + quoteId + '/pdf/check_pressgrid/' + tempHash + '/' + pressgridId)
  }

  downloadQuotePdf (quoteId, tempHash, pressgridId) {
    return this.axios.get('/quote/' + quoteId + '/pdf/download/' + tempHash + '/' + pressgridId)
  }

  fetchDealerQuoteExtras (dealerId) {
    return this.axios.get('/quotes/dealer/' + dealerId + '/extras')
  }

  /** Calculator Shutter **/

  fetchShutter (id) {
    return this.axios.get('/calculators/shutter/quote/' + id)
  }

  createShutter (data) {
    return this.axios.post('/calculators/shutter/quote', data)
  }

  duplicateShutter (id) {
    return this.axios.post('/calculators/shutter/quote/' + id + '/duplicate')
  }

  deleteShutter (id) {
    return this.axios.delete('/calculators/shutter/quote/' + id)
  }

  patchShutter (id, data) {
    return this.axios.patch('/calculators/shutter/quote/' + id, data)
  }

  fetchShutterPrice (type, engine, connection, width, height, measurementStyle) {
    return this.axios.get(`/calculators/shutter/price/${type}/${engine}/${connection}/${width}/${height}/${measurementStyle}`)
  }

  fetchShutterInfo (type, engine) {
    return this.axios.get('/calculators/shutter/info/' + type + '/' + engine)
  }

  fetchShutterHeight (type, engine, connection, width, measurementStyle) {
    return this.axios.get(`/calculators/shutter/height/${type}/${engine}/${connection}/${width}/${measurementStyle}`)
  }

  fetchShutterOptions (type) {
    return this.axios.get('/calculators/shutter/options', {
      params: {
        type: type
      }
    })
  }

  createShutterOption (quoteDataId, data) {
    return this.axios.post('/calculators/shutter/quote/' + quoteDataId + '/option', data)
  }

  patchShutterOption (quoteDataId, quoteOptionId, data) {
    return this.axios.patch('/calculators/shutter/quote/' + quoteDataId + '/option/' + quoteOptionId, data)
  }

  deleteShutterOption (quoteDataId, quoteOptionId) {
    return this.axios.delete('/calculators/shutter/quote/' + quoteDataId + '/option/' + quoteOptionId)
  }

  createShutterColor (quoteDataId, data) {
    return this.axios.post('/calculators/shutter/quote/' + quoteDataId + '/color', data)
  }

  patchShutterColor (quoteDataId, quoteColorId, data) {
    return this.axios.patch('/calculators/shutter/quote/' + quoteDataId + '/color/' + quoteColorId, data)
  }

  deleteShutterColor (quoteDataId, quoteColorId) {
    return this.axios.delete('/calculators/shutter/quote/' + quoteDataId + '/color/' + quoteColorId)
  }

  fetchShutterEnginesConnections (type) {
    return this.axios.get('/calculators/shutter/engines_connections/' + type)
  }

  /** Calculator Shutter **/

  fetchScreen (id) {
    return this.axios.get('/calculators/screen/quote/' + id)
  }

  createScreen (data) {
    return this.axios.post('/calculators/screen/quote', data)
  }

  duplicateScreen (id) {
    return this.axios.post('/calculators/screen/quote/' + id + '/duplicate')
  }

  deleteScreen (id) {
    return this.axios.delete('/calculators/screen/quote/' + id)
  }

  patchScreen (id, data) {
    return this.axios.patch('/calculators/screen/quote/' + id, data)
  }

  fetchScreenPrice (type, engine, connection, width, height, measurementStyle) {
    return this.axios.get(`/calculators/screen/price/${type}/${engine}/${connection}/${width}/${height}/${measurementStyle}`)
  }

  fetchScreenInfo (type, engine) {
    return this.axios.get('/calculators/screen/info/' + type + '/' + engine)
  }

  fetchScreenHeight (type, engine, connection, width, measurementStyle) {
    return this.axios.get(`/calculators/screen/height/${type}/${engine}/${connection}/${width}`)
  }

  fetchFabricTypes (type, engine, connection, width, height) {
    return this.axios.get(`/calculators/screen/fabric_types/${type}/${engine}/${connection}/${width}/${height}`)
  }

  fetchScreenOptions (type) {
    return this.axios.get('/calculators/screen/options', {
      params: {
        type: type
      }
    })
  }

  createScreenOption (quoteDataId, data) {
    return this.axios.post('/calculators/screen/quote/' + quoteDataId + '/option', data)
  }

  patchScreenOption (quoteDataId, quoteOptionId, data) {
    return this.axios.patch('/calculators/screen/quote/' + quoteDataId + '/option/' + quoteOptionId, data)
  }

  deleteScreenOption (quoteDataId, quoteOptionId) {
    return this.axios.delete('/calculators/screen/quote/' + quoteDataId + '/option/' + quoteOptionId)
  }

  createScreenColor (quoteDataId, data) {
    return this.axios.post('/calculators/screen/quote/' + quoteDataId + '/color', data)
  }

  patchScreenColor (quoteDataId, quoteColorId, data) {
    return this.axios.patch('/calculators/screen/quote/' + quoteDataId + '/color/' + quoteColorId, data)
  }

  deleteScreenColor (quoteDataId, quoteColorId) {
    return this.axios.delete('/calculators/screen/quote/' + quoteDataId + '/color/' + quoteColorId)
  }

  fetchScreenEnginesConnections (type) {
    return this.axios.get('/calculators/screen/engines_connections/' + type)
  }

  /** Calculator Articulating Screen **/

  fetchArticulatingScreen (id) {
    return this.axios.get('/calculators/articulating-screen/quote/' + id)
  }

  createArticulatingScreen (data) {
    return this.axios.post('/calculators/articulating-screen/quote', data)
  }

  duplicateArticulatingScreen (id) {
    return this.axios.post('/calculators/articulating-screen/quote/' + id + '/duplicate')
  }

  deleteArticulatingScreen (id) {
    return this.axios.delete('/calculators/articulating-screen/quote/' + id)
  }

  patchArticulatingScreen (id, data) {
    return this.axios.patch('/calculators/articulating-screen/quote/' + id, data)
  }

  fetchArticulatingScreenInfo (type) {
    return this.axios.get('/calculators/articulating-screen/info/' + type)
  }

  fetchArticulatingScreenReach (type, engine, connection, width) {
    return this.axios.get(`/calculators/articulating-screen/reach/${type}/${engine}/${connection}/${width}`)
  }

  fetchArticulatingScreenOptions (engine) {
    return this.axios.get('/calculators/articulating-screen/options', {
      params: {
        engine_type: engine
      }
    })
  }

  createArticulatingScreenOption (quoteDataId, data) {
    return this.axios.post('/calculators/articulating-screen/quote/' + quoteDataId + '/option', data)
  }

  patchArticulatingScreenOption (quoteDataId, quoteOptionId, data) {
    return this.axios.patch('/calculators/articulating-screen/quote/' + quoteDataId + '/option/' + quoteOptionId, data)
  }

  deleteArticulatingScreenOption (quoteDataId, quoteOptionId) {
    return this.axios.delete('/calculators/articulating-screen/quote/' + quoteDataId + '/option/' + quoteOptionId)
  }

  /** Academy **/

  fetchTrainings (data) {
    return this.axios.get('/academy/trainings', {
      params: data
    })
  }

  fetchPublishedTrainings () {
    return this.axios.get('/academy/trainings/published')
  }

  fetchTraining (id) {
    return this.axios.get('/academy/training/' + id)
  }

  createTraining (data) {
    return this.axios.post('/academy/training', data)
  }

  patchTraining (data) {
    return this.axios.patch('/academy/training/' + data.id, data)
  }

  fetchTrainingEvents (id) {
    return this.axios.get('/academy/training/' + id + '/events')
  }

  fetchTrainingEvent (trainingId, eventId) {
    return this.axios.get('/academy/training/' + trainingId + '/event/' + eventId)
  }

  createTrainingEvent (trainingId, data) {
    return this.axios.post('/academy/training/' + trainingId + '/event', data)
  }

  patchTrainingEvent (trainingId, data) {
    return this.axios.patch('/academy/training/' + trainingId + '/event/' + data.id, data)
  }

  fetchTrainingEventBooking (trainingId, eventId, id) {
    return this.axios.get('/academy/training/' + trainingId + '/event/' + eventId + '/booking/' + id)
  }

  createTrainingEventBooking (trainingId, eventId, data) {
    return this.axios.post('/academy/training/' + trainingId + '/event/' + eventId + '/booking', data)
  }

  patchTrainingEventBooking (trainingId, eventId, data) {
    return this.axios.patch('/academy/training/' + trainingId + '/event/' + eventId + '/booking/' + data.id, data)
  }

  deleteTrainingEventBooking (trainingId, eventId, id) {
    return this.axios.delete('/academy/training/' + trainingId + '/event/' + eventId + '/booking/' + id)
  }

  fetchMyBookings (dealerId) {
    return this.axios.get('/academy/my-bookings/' + dealerId)
  }

  fetchAllBookings (id, data) {
    return this.axios.get('/academy/training/' + id + '/bookings', {
      params: data
    })
  }

  exportBookings (id, data) {
    return this.axios.get('/academy/training/' + id + '/bookings/export', {
      params: data
    })
  }

  /** B2B orders **/

  fetchB2BOrders (data) {
    return this.axios.get('/b2b_orders', {
      params: data
    })
  }

  fetchB2BOrder (id, data) {
    return this.axios.get('/b2b_order/' + id, {
      params: data
    })
  }

  /** Globals **/

  fetchGlobals (data) {
    return this.axios.get('/generals', {
      params: data
    })
  }

  fetchGlobal (module, key) {
    return this.axios.get('/general/' + module + '/' + key)
  }

  patchGlobal (module, key, data) {
    return this.axios.patch('/general/' + module + '/' + key, data)
  }

  createGlobal (data) {
    return this.axios.post('/general', data)
  }

  /** Logs **/

  fetchLogs () {
    return this.axios.get('/logs')
  }

  /** User Trackings **/

  fetchUserTrackings (data) {
    return this.axios.get('/user-trackings', {
      params: data
    })
  }

  fetchUserTracking (id) {
    return this.axios.get('/user-trackings/' + id)
  }

  /** Leads **/

  fetchDealerLeads (dealerId, params) {
    return this.axios.get('/dealer/' + dealerId + '/leads', {
      params: params
    })
  }

  fetchDealerLead (dealerId, leadId) {
    return this.axios.get('/dealer/' + dealerId + '/lead/' + leadId)
  }

  createDealerLead (dealerId, data) {
    return this.axios.post('/dealer/' + dealerId + '/lead', data)
  }

  patchDealerLead (dealerId, leadId, data) {
    return this.axios.patch('/dealer/' + dealerId + '/lead/' + leadId, data)
  }

  fetchLeadsChannels () {
    return this.axios.get('/dealers/leads/channels')
  }

  fetchLeadsStatuses () {
    return this.axios.get('/dealers/leads/statuses')
  }

  createLeadComment (dealerId, leadId, data) {
    return this.axios.post('/dealer/' + dealerId + '/lead/' + leadId + '/comment', data)
  }

  fetchLeadComments (dealerId, leadId) {
    return this.axios.get('/dealer/' + dealerId + '/lead/' + leadId + '/comments')
  }

  fetchLeadQuotes (dealerId, leadId) {
    return this.axios.get('/dealer/' + dealerId + '/lead/' + leadId + '/quotes')
  }

  fetchProducts (params) {
    return this.axios.get('/b2b_orders/products', {
      params: params
    })
  }

  fetchProduct (id) {
    return this.axios.get('/b2b_orders/product/' + id)
  }

  patchProduct (product) {
    return this.axios.patch('/b2b_orders/product/' + product.id, product)
  }

  createProduct (product) {
    return this.axios.post('/b2b_orders/product', product)
  }

  deleteProduct (id) {
    return this.axios.delete('/b2b_orders/product/' + id)
  }

  createOrder (dealerId, products) {
    return this.axios.post('/b2b_orders/' + dealerId + '/order', products)
  }
}

const apiService = new ApiService()

export default apiService
export { apiService, apiService as ApiService }
