import Vue from 'vue'
import Vuex from 'vuex'

import { ApiService } from '@/services/admin/api.service'
import { UsersModule } from '@/store/admin/users.module'
import { RolesModule } from '@/store/admin/roles.module'
import { DealersModule } from '@/store/admin/dealers.module'
import { NewsModule } from '@/store/admin/news.module'
import { LoyaltyAdvantagesModule } from '@/store/admin/loyaltyadvantages.module'
import { LoyaltyUpgradesModule } from '@/store/admin/loyaltyupgrades.module'

import { HomesModule } from '@/store/iot/homes.module'
import { RoomsModule } from '@/store/iot/rooms.module'
import { DevicesModule } from '@/store/iot/devices.module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    UsersModule,
    RolesModule,
    DealersModule,
    NewsModule,
    LoyaltyAdvantagesModule,
    LoyaltyUpgradesModule,
    HomesModule,
    RoomsModule,
    DevicesModule
  },
  state: {
    images: {},
    access_token: localStorage.getItem('access_token') || null,
    refresh_token: localStorage.getItem('refresh_token') || null,
    currentUser: null,
    currentUserDealers: [],
    currentUserId: localStorage.getItem('currentUserId') || null
  },
  getters: {
    loggedIn: state => state.currentUser != null,
    getPageTitle: state => state.pageTitle,
    getCurrentUser: state => state.currentUser,
    getCurrentUserDealers: state => state.currentUserDealers ? state.currentUserDealers.sort((a, b) => a.name > b.name ? 1 : -1) : [],
    getImages: state => state.images
  },
  mutations: {
    setPageTitle (state, payload) {
      state.pageTitle = payload
    },
    setAccessToken (state, payload) {
      state.access_token = payload
    },
    setRefreshToken (state, payload) {
      state.refresh_token = payload
    },
    setCurrentUserDealers (state, payload) {
      state.currentUserDealers = payload
    },
    setCurrentUser (state, payload) {
      if (payload) {
        if (payload.roles.length > 0) {
          payload.currentUserPermissions = {}

          payload.roles.forEach((role, i) => {
            if (role.permissions.length > 0) {
              role.permissions.forEach((permission, j) => {
                if (payload.currentUserPermissions[permission.entity] === undefined) {
                  payload.currentUserPermissions[permission.entity] = {}
                }

                payload.currentUserPermissions[permission.entity][permission.action] = true
              })
            }
          })
        }

        state.currentUser = payload
        state.currentUserId = payload.id
      } else {
        state.currentUser = null
        state.currentUserId = null
      }
    },
    setImages (state, payload) {
      state.images = { ...state.images, ...payload }
    }
  },
  actions: {
    setPageTitle ({ commit }, payload) {
      commit('setPageTitle', payload)
    },
    async login ({ commit }, payload) {
      const res = await ApiService.login(payload)

      if (res.status === 200) {
        const accessToken = res.data.access_token
        const refreshToken = res.data.refresh_token

        localStorage.setItem('access_token', accessToken)
        localStorage.setItem('refresh_token', refreshToken)
        localStorage.setItem('currentUserId', res.data.user.id)

        commit('setAccessToken', accessToken)
        commit('setRefreshToken', refreshToken)
        commit('setCurrentUser', res.data.user)
      }

      return res
    },
    async fetchCurrentUser ({ state, commit }) {
      return await ApiService.fetchUser(state.currentUserId)
        .then(res => {
          if (res.status === 200) {
            localStorage.setItem('currentUserId', res.data.id)
            commit('setCurrentUser', res.data)
          }
        }).catch(() => {
          localStorage.removeItem('access_token')
          localStorage.removeItem('refresh_token')
          localStorage.removeItem('currentUserId')
          localStorage.removeItem('chosenDealerId')
          commit('setAccessToken', null)
          commit('setRefreshToken', null)
          commit('setCurrentUser', null)
        })
    },
    async refreshToken ({ commit }) {
      let isSuccesful = false
      await ApiService.refresh()
        .then(res => {
          if (res.status === 200) {
            const accessToken = res.data.access_token
            const refreshToken = res.data.refresh_token
            localStorage.setItem('access_token', accessToken)
            localStorage.setItem('refresh_token', refreshToken)
            commit('setAccessToken', accessToken)
            commit('setRefreshToken', refreshToken)
            isSuccesful = true
          }
        })

      return isSuccesful
    },
    async resetPassword ({ commit }, payload) {
      return await ApiService.resetPassword(payload.hash, payload.password, payload.passwordConfirm).then((res) => {
        if (res.status === 200) {
          const accessToken = res.data.access_token
          const refreshToken = res.data.refresh_token

          localStorage.setItem('access_token', accessToken)
          localStorage.setItem('refresh_token', refreshToken)
          localStorage.setItem('currentUserId', res.data.user.id)

          commit('setAccessToken', accessToken)
          commit('setRefreshToken', refreshToken)
          commit('setCurrentUser', res.data.user)
        }
      })
    },
    removeToken ({ getters, commit }) {
      localStorage.removeItem('access_token')
      localStorage.removeItem('refresh_token')
      localStorage.removeItem('currentUserId')
      localStorage.removeItem('chosenDealerId')
      commit('setAccessToken', null)
      commit('setRefreshToken', null)
      commit('setCurrentUser', null)
    },
    async fetchImages ({ state, commit }, payload) {
      if (payload.objects.length > 0) {
        const imageIds = []
        let value = 'image_file_id'

        if (payload.value) {
          value = payload.value
        }

        payload.objects.forEach((obj) => {
          if (obj.translations) {
            obj.translations.forEach((translation) => {
              if (translation[value] && !state.images[translation[value]]) {
                imageIds.push(translation[value])
              }
            })
          }

          if (obj[value] && !state.images[obj[value]]) {
            imageIds.push(obj[value])
          }
        })

        if (imageIds.length > 0) {
          const response = await ApiService.getFiles(imageIds.toString())
          const images = {}

          if (response.status === 200) {
            response.data.files.forEach((obj) => {
              images[obj.id] = obj
            })

            commit('setImages', images)
          }
        }
      }
    },
    async fetchCurrentUserDealers ({ state, commit }) {
      await ApiService.fetchUserDealers(state.currentUserId).then(res => {
        if (res.status === 200) {
          commit('setCurrentUserDealers', res.data)
        }
      })
    }
  }
})
