import { ApiService } from '@/services/admin/api.service'

export const LoyaltyUpgradesModule = {
  namespaced: true,
  state: {
    loyaltyUpgrade: null,
    loyaltyUpgrades: [],
    total: 0,
    page: 0,
    perPage: 25
  },
  getters: {
    getLoyaltyUpgrade: (state) => state.loyaltyUpgrade,
    getLoyaltyUpgrades: (state) => state.loyaltyUpgrades
  },
  mutations: {
    setLoyaltyUpgrades (state, payload) {
      state.loyaltyUpgrades = payload.points
      state.total = payload.total
    },
    setLoyaltyUpgrade (state, payload) {
      state.loyaltyUpgrade = payload
    },
    setPagination (state, payload) {
      state.page = payload.page !== undefined ? payload.page : state.page
      state.perPage = payload.perPage !== undefined ? payload.perPage : state.perPage
    }
  },
  actions: {
    async fetchLoyaltyUpgrades ({ state, commit }, payload = {}) {
      commit('setPagination', payload)
      await ApiService.fetchLoyaltyUpgrades(state.page, state.perPage, payload.search).then(res => {
        commit('setLoyaltyUpgrades', res.data)
      })
    },
    async fetchLoyaltyUpgrade ({ state, commit }, payload = {}) {
      await ApiService.fetchLoyaltyUpgrade(payload).then(res => {
        commit('setLoyaltyUpgrade', res.data)
      })
    }
  }
}
