<template>
  <div :class="`formulate-input-element formulate-input-element--${context.class}`" :data-type="context.type" v-if="model !== null || model === undefined">
    <div class="editor">
      <div v-if="editor" class="editor-buttons">
        <button :class="{ 'is-active': editor.isActive('bold') }" type="button" @click="editor.chain().focus().toggleBold().run()">
          <Icon width="20" icon="ic:baseline-format-bold"/>
        </button>
        <button :class="{ 'is-active': editor.isActive('italic') }" type="button" @click="editor.chain().focus().toggleItalic().run()">
          <Icon width="20" icon="ic:baseline-format-italic"/>
        </button>
        <button :class="{ 'is-active': editor.isActive('strike') }" type="button" @click="editor.chain().focus().toggleStrike().run()">
          <Icon width="20" icon="ic:baseline-format-strikethrough"/>
        </button>
        <button :class="{ 'is-active': editor.isActive('code') }" type="button" @click="editor.chain().focus().toggleCode().run()">
          <Icon width="20" icon="ic:baseline-code"/>
        </button>
        <button :class="{ 'is-active': editor.isActive('paragraph') }" type="button" @click="editor.chain().focus().setParagraph().run()">
          paragraph
        </button>
        <button :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }" type="button" @click="editor.chain().focus().toggleHeading({ level: 1 }).run()">
          h1
        </button>
        <button :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }" type="button" @click="editor.chain().focus().toggleHeading({ level: 2 }).run()">
          h2
        </button>
        <button :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }" type="button" @click="editor.chain().focus().toggleHeading({ level: 3 }).run()">
          h3
        </button>
        <button :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }" type="button" @click="editor.chain().focus().toggleHeading({ level: 4 }).run()">
          h4
        </button>
        <button :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }" type="button" @click="editor.chain().focus().toggleHeading({ level: 5 }).run()">
          h5
        </button>
        <button :class="{ 'is-active': editor.isActive('heading', { level: 6 }) }" type="button" @click="editor.chain().focus().toggleHeading({ level: 6 }).run()">
          h6
        </button>
        <button :class="{ 'is-active': editor.isActive('link') }" type="button" @click="openModal('link')">
          <Icon width="20" icon="ic:baseline-insert-link"/>
        </button>
        <button v-if="editor.isActive('link')" @click="editor.chain().focus().unsetLink().run()">
          <Icon width="20" icon="ic:baseline-link-off"/>
        </button>
        <button :class="{ 'is-active': editor.isActive('bulletList') }" type="button" @click="editor.chain().focus().toggleBulletList().run()">
          <Icon width="20" icon="ic:baseline-format-list-bulleted"/>
        </button>
        <button :class="{ 'is-active': editor.isActive('orderedList') }" type="button" @click="editor.chain().focus().toggleOrderedList().run()">
          <Icon width="20" icon="ic:baseline-format-list-numbered"/>
        </button>
        <button type="button" @click="openModal('image')">
          <Icon width="20" icon="ic:outline-image"/>
        </button>
        <button type="button" @click="openModal('video')">
          <Icon width="20" icon="ic:outline-video-camera-back"/>
        </button>
      </div>
      <editor-content :editor="editor"/>

      <modal :adaptive="true" :height="'auto'" :name="'editor-modal-' + context.name" :reset="true" :scrollable="true" class="modal">
        <formulate-form @submit="submitEditorModal">
          <div @click="closeModal()">
            <Icon class="btn-close" icon="ic:baseline-close"/>
          </div>

          <div v-if="modalType === 'image'">
            <formulate-input :uploader="uploadImage" label="Afbeelding" name="files" type="file" upload-behavior="live"></formulate-input>
          </div>

          <div v-if="modalType === 'video'">
            <formulate-input label="Video url" name="url" type="text"></formulate-input>
            <formulate-input input-class="btn" label="Toepassen" type="submit"></formulate-input>
          </div>

          <div v-if="modalType === 'link'">
            <formulate-input label="Link url" name="url" type="text"></formulate-input>
            <formulate-input label="Open in een nieuwe tab" name="target" type="checkbox"></formulate-input>
            <formulate-input input-class="btn" label="Toepassen" type="submit"></formulate-input>
          </div>
        </formulate-form>
      </modal>
    </div>
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Link from '@tiptap/extension-link'
import ExternalVideo from './external-video'
import CustomImage from './custom-image-3'
import { ApiService } from '@/services/admin/api.service'
import { Icon } from '@iconify/vue2'

export default {
  components: {
    EditorContent,
    Icon
  },
  props: {
    context: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      editor: null,
      modalType: '',
      isUploading: false
    }
  },
  watch: {
    'context.model' (value) {
      const isSame = this.editor.getHTML() === value

      if (isSame) {
        return
      }

      this.editor.commands.setContent(this.context.model, false)
    }
  },
  mounted () {
    this.editor = new Editor({
      extensions: [
        StarterKit,
        ExternalVideo,
        Link.configure({
          openOnClick: false
        }),
        CustomImage.configure({
          HTMLAttributes: {
            class: 'custom-image'
          }
        })
      ],
      content: this.context.model,
      onUpdate: () => {
        this.context.model = this.editor.getHTML()
      }
    })
  },
  methods: {
    openModal (modalType) {
      this.modalType = modalType
      this.$modal.show('editor-modal-' + this.context.name)
    },
    closeModal () {
      this.$modal.hide('editor-modal-' + this.context.name)
    },
    submitEditorModal (formData) {
      const url = formData.url

      if (this.modalType === 'video') {
        if (url) {
          this.editor.chain().focus().setExternalVideo({ src: url }).run()
        }
      }

      if (this.modalType === 'link') {
        this.editor.chain().focus().extendMarkRange('link').setLink({
          href: url,
          target: formData.target ? '_blank' : ''
        }).run()
      }

      this.closeModal()
    },
    async uploadImage (file, progress, error, option) {
      this.isUploading = true

      const formData = new FormData()
      formData.append('files', file)
      const res = await ApiService.uploadFiles(formData)

      if (res.status === 200) {
        this.isUploading = false
        const fileId = res.data[0]
        const fileData = (await ApiService.getFile(fileId)).data

        if (fileData) {
          this.editor.chain().focus().setImage({ src: fileData }).run()
          this.closeModal()
        }
      }
    }
  },
  computed: {
    model () {
      return this.context.model
    }
  }
  // beforeDestroy () {
  //   this.editor.destroy()
  // }
}
</script>
