import axios from 'axios'
import store from '../store/index'
import router from '@/router/index'
import { DateTime } from 'luxon'

export class DefaultService {
  axios = null
  refreshing = false

  constructor (baseURL = '/api') {
    const whiteListedKeys = [
      // 'slot', 'time',
      // 'event_start', 'event_end',
      'created', 'updated', 'seniority', 'last_calculated_on', 'last_login']

    function covertDateTimesRecursive (data) {
      if (data && Object.keys(data).length > 0) {
        Object.keys(data).forEach(key => {
          if (Array.isArray(data[key]) || typeof data[key] === 'object') {
            data[key] = covertDateTimesRecursive(data[key])
          } else if (whiteListedKeys.includes(key)) {
            const datetimeValue = DateTime.fromISO(data[key]).toUTC().toISO({ includeOffset: false })
            if (datetimeValue != null) {
              data[key] = datetimeValue
            }
          }
        })
      }

      return data
    }

    this.axios = axios.create({
      headers: {
        Timezone: DateTime.now().zoneName
      },
      baseURL,
      transformRequest: [
        function (data, headers) {
          return covertDateTimesRecursive(data)
        },
        ...axios.defaults.transformRequest
      ],
      transformResponse: [
        function (data, h) {
          function covertDateTimesRecursive (data) {
            if (data && Object.keys(data).length > 0) {
              Object.keys(data).forEach(key => {
                if (Array.isArray(data[key]) || typeof data[key] === 'object') {
                  data[key] = covertDateTimesRecursive(data[key])
                } else if (whiteListedKeys.includes(key)) {
                  const datetimeValue = DateTime.fromISO(data[key], { zone: 'utc' }).toLocal().toISO({ includeOffset: false })
                  if (datetimeValue != null) {
                    data[key] = datetimeValue
                  }
                }
              })
            }

            return data
          }

          if (!h['content-type'].includes('text/csv')) {
            return JSON.stringify(covertDateTimesRecursive(JSON.parse(data)))
          }

          return data
        },
        ...axios.defaults.transformResponse
      ]
    })

    this.axios.interceptors.request.use(config => {
      config.params = covertDateTimesRecursive(config.params)

      let accessToken = localStorage.getItem('access_token')
      let refreshToken = localStorage.getItem('refresh_token')

      if (accessToken && accessToken === 'null') {
        accessToken = null
        refreshToken = null
      }

      if (accessToken && config.url !== '/refresh') {
        config.headers.Authorization = `Bearer ${accessToken}`
      }

      if (refreshToken && config.url === '/refresh') {
        config.headers.Authorization = `Bearer ${refreshToken}`
      }

      return config
    }, error => {
      return Promise.reject(error)
    })

    this.axios.interceptors.response.use(null, async err => {
      if (err.response) {
        const originalRequest = err.response.config

        if ((err.response.status === 422 && err.response.data.detail === 'Signature has expired') || err.response.status === 401) {
          if (err.response.config.url === '/login' && err.response.status === 401) {
            return err
          }

          if (err.response.config.url === '/refresh') {
            await store.dispatch('removeToken')
            return await router.push({ name: 'ww.login', params: { locale: 'nl' } })
          }

          if (!this.refreshing) {
            this.refreshing = true
            const res = await store.dispatch('refreshToken')
            this.refreshing = false

            if (res) {
              return this.axios(originalRequest)
            }
          }
        }
      }

      return err
    })
  }
}
