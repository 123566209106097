import store from '@/store'

export class UserPermissions {
  static check (permissions) {
    if (permissions.length === 0) {
      return true
    }

    const currentUser = store.getters.getCurrentUser

    if (currentUser && currentUser.is_superuser) {
      return true
    }

    if (permissions.length === 0) {
      return false
    }

    let hasPermission = true
    permissions.forEach((permissionString, i) => {
      const permission = permissionString.split('.')

      if (currentUser && currentUser.currentUserPermissions?.[permission[0]]?.[permission[1]] === undefined) {
        hasPermission = false
      }
    })

    return hasPermission
  }
}
