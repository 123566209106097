import mixins from '@/mixins'
import i18n from '@/i18n'

export const worldRoutes = [
  {
    path: '/:locale',
    component: () => import('@/views/world/Layout'),
    beforeEnter: (to, from, next) => {
      const locale = to.params.locale

      if (!mixins.data().activeLocalesFront[locale]) {
        return next('/nl')
      }

      if (i18n.locale !== locale) {
        i18n.locale = locale
      }

      return next()
    },
    children: [
      {
        path: '',
        name: 'ww.dashboard',
        component: () => import('@/views/world/Dashboard')
      },
      {
        path: 'detail/:id',
        name: 'ww.menuitem',
        component: () => import('@/views/world/MenuItemChildren')
      },
      {
        path: 'login/:redirect?/:redirectParams?',
        name: 'ww.login',
        component: () => import('@/views/world/Login')
      },
      {
        path: 'profile',
        name: 'ww.profile',
        component: () => import('@/views/world/Profile')
      },
      {
        path: 'dealer',
        name: 'ww.dealer',
        component: () => import('@/views/world/Dealer')
      },
      {
        path: 'logout',
        name: 'ww.logout',
        component: () => import('@/views/world/Logout')
      },
      {
        path: 'password/reset',
        name: 'ww.passwordreset',
        component: () => import('@/views/world/PasswordResetRequest'),
        meta: {
          allowed: true
        }
      },
      {
        path: 'password/reset/claim',
        name: 'ww.passwordreset.claim',
        component: () => import('@/views/world/PasswordResetClaim'),
        meta: {
          allowed: true
        }
      },
      {
        path: 'loyalty',
        name: 'ww.loyalty',
        component: () => import('@/views/world/Loyalty')
      },
      {
        path: 'loyalty/advantages',
        name: 'ww.loyalty.advantages',
        component: () => import('@/views/world/LoyaltyAdvantages')
      },
      {
        path: 'loyalty/upgrade',
        name: 'ww.loyalty.upgrade',
        component: () => import('@/views/world/LoyaltyUpgrade')
      },
      {
        path: 'news',
        name: 'ww.news',
        component: () => import('@/views/world/NewsOverview')
      },
      {
        path: 'news/:id',
        name: 'ww.news.detail',
        component: () => import('@/views/world/NewsDetail')
      },
      {
        path: 'orders',
        name: 'ww.orders',
        component: () => import('@/views/world/Orders')
      },
      {
        path: 'powderlist',
        name: 'ww.powderlist',
        component: () => import('@/views/world/Powderlist')
      },
      {
        path: 'academy',
        name: 'ww.trainings',
        component: () => import('@/views/world/Trainings')
      },
      {
        path: 'academy/:id',
        name: 'ww.training',
        component: () => import('@/views/world/Training')
      },
      {
        path: 'quotes',
        name: 'ww.quotes',
        component: () => import('@/views/world/Quotes')
      },
      {
        path: 'quotes/:id',
        name: 'ww.quotes.detail',
        component: () => import('@/views/world/Quote')
      },
      {
        path: 'quotes/:quoteId/calc/shutters',
        name: 'ww.quotes.calc.shutter_select',
        component: () => import('@/views/world/calculators/ShutterSelect')
      },
      {
        path: 'quotes/:quoteId/calc/screens',
        name: 'ww.quotes.calc.screen_select',
        component: () => import('@/views/world/calculators/ScreenSelect')
      },
      {
        path: 'quotes/:quoteId/calc/articulating-screen/:id',
        name: 'ww.quotes.calc.as',
        component: () => import('@/views/world/calculators/ArticulatingScreen')
      },
      {
        path: 'quotes/:quoteId/calc/shutter/:id',
        name: 'ww.quotes.calc.shutter',
        component: () => import('@/views/world/calculators/Shutter')
      },
      {
        path: 'quotes/:quoteId/calc/screen/:id',
        name: 'ww.quotes.calc.screen',
        component: () => import('@/views/world/calculators/Screen')
      },
      {
        path: 'quotes/:quoteId/calc/shutters-bulk',
        name: 'ww.quotes.calc.shutter_bulk',
        component: () => import('@/views/world/calculators/ShutterBulk')
      },
      {
        path: 'quotes/:quoteId/calc/screen-bulk',
        name: 'ww.quotes.calc.screen_bulk',
        component: () => import('@/views/world/calculators/ScreenBulk')
      },
      {
        path: 'leads',
        name: 'ww.leads',
        component: () => import('@/views/world/Leads')
      },
      {
        path: 'leads/:id?',
        name: 'ww.leads.detail',
        component: () => import('@/views/world/Lead')
      },
      {
        path: 'leads/new',
        name: 'ww.leads.detail.new',
        component: () => import('@/views/world/Lead')
      },
      {
        path: 'products',
        name: 'ww.products',
        component: () => import('@/views/world/Products'),
        meta: {
          permissions: ['b2b_orders_product.view']
        }
      },
      {
        path: 'cart',
        name: 'ww.cart',
        component: () => import('@/views/world/Cart'),
        meta: {
          permissions: ['b2b_orders_product.view']
        }
      }
    ]
  },
  {
    path: '/',
    redirect () {
      return '/nl/'
    }
  }
]
