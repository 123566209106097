import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/styles/index.scss'
import VueFormulate from '@braid/vue-formulate'
import VModal from 'vue-js-modal'
import { en, fr, nl } from '@braid/vue-formulate-i18n'
import VueSimpleContextMenu from 'vue-simple-context-menu'
import mixins from '@/mixins'
import i18n from '@/i18n'

import VueGtag from 'vue-gtag'
import VueSignaturePad from 'vue-signature-pad'
import TipTap from '@/components/formulate/TipTap'

import L from 'leaflet'
import 'leaflet/dist/leaflet.css'

delete L.Icon.Default.prototype._getIconUrl

Vue.config.productionTip = false

Vue.component('TipTap', TipTap)

Vue.use(VueFormulate, {
  library: {
    tiptap: {
      classification: 'text',
      component: 'TipTap',
      slotProps: {
        component: [
          'customUploadUrl'
        ]
      }
    }
  },
  classes: {
    wrapper: 'flex',
    outer: (context, classes) => classes.concat(['required-' + (typeof context.validation === 'string' && context.validation.includes('required'))])
  },
  validationNameStrategy: ['validationName', 'label', 'name', 'type'],
  plugins: [nl, en, fr]
})

Vue.use(VueSignaturePad)
Vue.use(VModal)
Vue.mixin(mixins)
Vue.component('vue-simple-context-menu', VueSimpleContextMenu)
Vue.use(VueGtag, { config: { id: 'UA-91885939-3' } }, router)

// Cypress automatically sets window.Cypress by default
if (window.Cypress) {
  window.__store__ = store
}

new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
