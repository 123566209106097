import VueI18n from 'vue-i18n'
import Vue from 'vue'

Vue.use(VueI18n)

const numberFormats = {
  'nl-BE': {
    currency: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol'
    }
  }
}

function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

const i18n = new VueI18n({
  numberFormats,
  locale: 'nl',
  fallbackLocale: 'nl',
  messages: loadLocaleMessages()
})

export default i18n
