export const adminRoutes = [{
  path: '/admin',
  component: () => import('@/views/admin/Layout'),
  meta: {
    title: 'admin.title'
  },
  children: [
    {
      path: '/',
      name: 'admin.dashboard',
      component: () => import('@/views/admin/Dashboard'),
      meta: {
        title: 'dashboard.title',
        permissions: ['user.accessAdminPanel']
      }
    },
    {
      path: '/admin/appointments',
      name: 'admin.appointments',
      component: () => import('@/views/admin/Appointments'),
      meta: {
        title: 'appointments.title',
        permissions: ['appointment.view']
      }
    },
    {
      path: '/admin/appointments/overview',
      name: 'admin.appointments.overview',
      component: () => import('@/views/admin/AppointmentsOverview'),
      meta: {
        title: 'appointments.title',
        permissions: ['appointment.view']
      }
    },
    {
      path: '/admin/users',
      name: 'admin.users',
      component: () => import('@/views/admin/Users'),
      meta: {
        title: 'users.title',
        permissions: ['user.view']
      }
    },
    {
      path: '/admin/users/add',
      name: 'admin.user.add',
      component: () => import('@/views/admin/User'),
      meta: {
        title: 'user.title'
      }
    },
    {
      path: '/admin/users/:id',
      name: 'admin.user',
      component: () => import('@/views/admin/User'),
      meta: {
        title: 'user.title'
      }
    },
    {
      path: '/admin/roles',
      name: 'admin.roles',
      component: () => import('@/views/admin/Roles'),
      meta: {
        title: 'roles.title'
      }
    },
    {
      path: '/admin/dealers',
      name: 'admin.dealers',
      component: () => import('@/views/admin/Dealers'),
      meta: {
        title: 'dealers.title',
        permissions: ['dealer.view']
      }
    },
    // {
    //   path: '/admin/dealers/import',
    //   name: 'admin.dealers.import',
    //   component: () => import('@/views/admin/DealerImport'),
    //   meta: {
    //     title: 'dealerimport.title',
    //     permissions: ['dealer.view', 'dealer.create']
    //   }
    // },
    {
      path: '/admin/dealers/add',
      name: 'admin.dealer.add',
      component: () => import('@/views/admin/Dealer'),
      meta: {
        title: 'dealer.title',
        permissions: ['dealer.view', 'dealer.create']
      }
    },
    {
      path: '/admin/dealers/:id',
      name: 'admin.dealer',
      component: () => import('@/views/admin/Dealer'),
      meta: {
        title: 'dealer.title',
        permissions: ['dealer.view', 'dealer.edit']
      }
    },
    {
      path: '/admin/menu/items',
      name: 'admin.menuitems',
      component: () => import('@/views/admin/MenuItems'),
      meta: {
        title: 'menuitems.title',
        permissions: ['menu.edit']
      }
    },
    {
      path: '/admin/menu/items/add',
      name: 'admin.menuitem.add',
      component: () => import('@/views/admin/MenuItem'),
      meta: {
        title: 'menuitem.title',
        permissions: ['menu.create']
      }
    },
    {
      path: '/admin/menu/items/:id',
      name: 'admin.menuitem',
      component: () => import('@/views/admin/MenuItem'),
      meta: {
        title: 'menuitem.title',
        permissions: ['menu.edit']
      }
    },
    {
      path: '/admin/news',
      name: 'admin.news',
      component: () => import('@/views/admin/News'),
      meta: {
        title: 'news.title',
        permissions: ['news.view']
      }
    },
    {
      path: '/admin/news-item/:id',
      name: 'admin.newsitem',
      component: () => import('@/views/admin/NewsItem'),
      meta: {
        title: 'newsitem.title',
        permissions: ['news.view', 'news.edit']
      }
    },
    {
      path: '/admin/news-item',
      name: 'admin.newsitem.add',
      component: () => import('@/views/admin/NewsItem'),
      meta: {
        title: 'newsitem.title',
        permissions: ['news.view', 'news.edit']
      }
    },
    {
      path: '/admin/loyalty-upgrades',
      name: 'admin.loyaltyupgrades',
      component: () => import('@/views/admin/LoyaltyUpgrades'),
      meta: {
        title: 'loyaltyupgrades.title',
        permissions: ['loyalty_upgrade.view']
      }
    },
    {
      path: '/admin/loyalty-upgrade/:id?',
      name: 'admin.loyaltyupgrade',
      component: () => import('@/views/admin/LoyaltyUpgrade'),
      meta: {
        title: 'loyaltyupgrades.title',
        permissions: ['loyalty_upgrade.edit']
      }
    },
    {
      path: '/admin/products',
      name: 'admin.products',
      component: () => import('@/views/admin/Products'),
      meta: {
        title: 'products.title',
        permissions: ['b2b_orders_product.view']
      }
    },
    {
      path: '/admin/product/:id?',
      name: 'admin.product',
      component: () => import('@/views/admin/Product'),
      meta: {
        title: 'product.title',
        permissions: ['b2b_orders_product.edit']
      }
    },
    {
      path: '/admin/loyalty-advantages',
      name: 'admin.loyaltyadvantages',
      component: () => import('@/views/admin/LoyaltyAdvantages'),
      meta: {
        title: 'loyaltyadvantages.title',
        permissions: ['loyalty_advantage.view']
      }
    },
    {
      path: '/admin/loyalty-advantage/:id',
      name: 'admin.loyaltyadvantage',
      component: () => import('@/views/admin/LoyaltyAdvantage'),
      meta: {
        title: 'loyaltyadvantage.title',
        permissions: ['loyalty_advantage.view', 'loyalty_advantage.edit']
      }
    },
    {
      path: '/admin/loyalty-advantage',
      name: 'admin.loyaltyadvantage.add',
      component: () => import('@/views/admin/LoyaltyAdvantage'),
      meta: {
        title: 'loyaltyadvantage.title',
        permissions: ['loyalty_advantage.view', 'loyalty_advantage.edit']
      }
    },
    {
      path: '/admin/trainings',
      name: 'admin.trainings',
      component: () => import('@/views/admin/Trainings'),
      meta: {
        title: 'trainings.title',
        permissions: ['academy_training.view']
      }
    },
    {
      path: '/admin/trainings/add',
      name: 'admin.training.add',
      component: () => import('@/views/admin/Training'),
      meta: {
        title: 'training.title',
        permissions: ['academy_training.create']
      }
    },
    {
      path: '/admin/trainings/:id',
      name: 'admin.training',
      component: () => import('@/views/admin/Training'),
      meta: {
        title: 'training.title',
        permissions: ['academy_training.edit']
      }
    },
    {
      path: '/admin/trainings/:trainingId/event/add',
      name: 'admin.training.event.add',
      component: () => import('@/views/admin/TrainingEvent'),
      meta: {
        title: 'training.event.title',
        permissions: ['academy_training.edit']
      }
    },
    {
      path: '/admin/trainings/:trainingId/event/:id',
      name: 'admin.training.event',
      component: () => import('@/views/admin/TrainingEvent'),
      meta: {
        title: 'training.event.title',
        permissions: ['academy_training.edit']
      }
    },
    {
      path: '/admin/trainings/:trainingId/event/:eventId/booking',
      name: 'admin.training.event.booking.add',
      component: () => import('@/views/admin/TrainingEventBooking'),
      meta: {
        title: 'training.event.booking.title',
        permissions: ['academy_training.edit']
      }
    },
    {
      path: '/admin/trainings/:trainingId/event/:eventId/booking/:id',
      name: 'admin.training.event.booking',
      component: () => import('@/views/admin/TrainingEventBooking'),
      meta: {
        title: 'training.event.booking.title',
        permissions: ['academy_training.edit']
      }
    },
    {
      path: '/admin/globals',
      name: 'admin.globals',
      component: () => import('@/views/admin/Globals'),
      meta: {
        title: 'globals.title',
        permissions: ['general.view']
      }
    },
    {
      path: '/admin/b2b-orders/:id',
      name: 'admin.b2b_order',
      component: () => import('@/views/admin/B2BOrder.vue'),
      meta: {
        title: 'b2b_orders.title',
        permissions: ['general.view']
      }
    },
    {
      path: '/admin/b2b-orders',
      name: 'admin.b2b_orders',
      component: () => import('@/views/admin/B2BOrders.vue'),
      meta: {
        title: 'b2b_orders.title',
        permissions: ['general.view']
      }
    },
    {
      path: '/admin/globals/add',
      name: 'admin.globals.add',
      component: () => import('@/views/admin/Global'),
      meta: {
        title: 'global.title',
        permissions: ['general.edit']
      }
    },
    {
      path: '/admin/globals/:module/:key',
      name: 'admin.global',
      component: () => import('@/views/admin/Global'),
      meta: {
        title: 'global.title',
        permissions: ['general.edit']
      }
    },
    {
      path: '/admin/logs',
      name: 'admin.logs',
      component: () => import('@/views/admin/Logs'),
      meta: {
        title: 'logs.title',
        permissions: ['log.view']
      }
    },
    {
      path: '/admin/calculator/settings',
      name: 'admin.calculator.settings',
      component: () => import('@/views/admin/CalculatorSettings'),
      meta: {
        title: 'calculator_settings.title',
        permissions: ['superadmin']
      }
    },
    {
      path: '/admin/user-trackings',
      name: 'admin.usertrackings',
      component: () => import('@/views/admin/UserTrackings'),
      meta: {
        title: 'user_trackings.title',
        permissions: ['user_tracking.view']
      }
    },
    {
      path: '/admin/user-tracking/:id',
      name: 'admin.usertracking',
      component: () => import('@/views/admin/UserTracking'),
      meta: {
        title: 'user_tracking.title',
        permissions: ['user_tracking.view']
      }
    }
  ]
}]
