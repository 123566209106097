import { ApiService } from '@/services/iot/api.service'

export const DevicesModule = {
  namespaced: true,
  state: {
    devices: null,
    averagesDay: null,
    averagesWeek: null,
    averagesMonth: null,
    stats: null,
    last: null
  },
  getters: {
    getDevices: (state) => state.devices,
    getAveragesDay: (state) => state.averagesDay,
    getAveragesWeek: (state) => state.averagesWeek,
    getAveragesMonth: (state) => state.averagesMonth,
    getStats: (state) => state.stats,
    getLast: (state) => state.last
  },
  mutations: {
    setDevices (state, payload) {
      state.devices = payload
    },
    setAveragesDay (state, payload) {
      state.averagesDay = payload
    },
    setAveragesWeek (state, payload) {
      state.averagesWeek = payload
    },
    setAveragesMonth (state, payload) {
      state.averagesMonth = payload
    },
    setStats (state, payload) {
      state.stats = payload
    },
    setLast (state, payload) {
      state.last = payload
    }
  },
  actions: {
    async fetchDevices ({ commit }, payload = {}) {
      await ApiService.getDevice(payload.homeId, payload.roomId).then(res => {
        commit('setDevices', res.data)
      })
    },
    async createDevice ({ commit }, payload = {}) {
      await ApiService.createDevice(payload.homeId, payload.roomId, payload.device).then(res => {
        commit('RoomsModule/addDevice', { roomId: payload.roomId, data: res.data }, { root: true })
      })
    },
    async fetchAveragesDay ({ commit }, payload = {}) {
      await ApiService.getAverages(payload.homeId, payload.roomId, payload.deviceId, payload.time).then(res => {
        commit('setAveragesDay', res.data)
      })
    },
    async fetchAveragesWeek ({ commit }, payload = {}) {
      await ApiService.getAverages(payload.homeId, payload.roomId, payload.deviceId, payload.time).then(res => {
        commit('setAveragesWeek', res.data)
      })
    },
    async fetchAveragesMonth ({ commit }, payload = {}) {
      await ApiService.getAverages(payload.homeId, payload.roomId, payload.deviceId, payload.time).then(res => {
        commit('setAveragesMonth', res.data)
      })
    },
    async fetchStats ({ commit }, payload = {}) {
      await ApiService.getStats(payload.homeId, payload.roomId, payload.deviceId).then(res => {
        commit('setStats', res.data)
      })
    },
    async fetchLast ({ commit }, payload = {}) {
      await ApiService.getLast(payload.homeId, payload.roomId, payload.deviceId).then(res => {
        commit('setLast', res.data)
      })
    },
    async rebootDevice ({ commit }, payload = {}) {
      await ApiService.rebootDevice(payload.homeId, payload.roomId, payload.deviceId)
    },
    async changeRPM ({ commit }, payload = {}) {
      await ApiService.changeRPM(payload.homeId, payload.roomId, payload.deviceId, { index: payload.index, value: payload.value })
    }
  }
}
