import { ApiService } from '@/services/admin/api.service'

export const UsersModule = {
  namespaced: true,
  state: {
    users: [],
    usersTotal: 0,
    page: 0,
    perPage: 25,
    sortDir: 'asc',
    sortBy: 'id',
    withoutDealers: false
  },
  getters: {
    getUsers: (state) => state.users
  },
  mutations: {
    setUsers (state, payload) {
      state.users = payload.users
      state.usersTotal = payload.total
    },
    setPagination (state, payload) {
      state.page = payload.page !== undefined ? payload.page : state.page
      state.perPage = payload.perPage !== undefined ? payload.perPage : state.perPage
    },
    setSortOrder (state, payload) {
      state.sortBy = payload.sortBy !== undefined ? payload.sortBy : state.sortBy
      state.sortDir = payload.sortDir !== undefined ? payload.sortDir : state.sortDir
      state.withoutDealers = payload.withoutDealers !== undefined ? payload.withoutDealers : state.withoutDealers
    }
  },
  actions: {
    async fetchUsers ({ state, commit }, payload = {}) {
      commit('setPagination', payload)
      commit('setSortOrder', payload)
      await ApiService.fetchUsers(state.page, state.perPage, payload.search, state.sortBy, state.sortDir, state.withoutDealers).then(res => {
        commit('setUsers', res.data)
      })
    }
  }
}
