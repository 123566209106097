import Vue from 'vue'
import VueRouter from 'vue-router'

import { iotRoutes } from '@/router/iot.routes'
import { adminRoutes } from '@/router/admin.routes'
import { worldRoutes } from '@/router/world.routes'
import { UserPermissions } from '@/permissions'
import store from '@/store/index'
import i18n from '@/i18n'

Vue.use(VueRouter)

const routes = [
  ...iotRoutes,
  ...adminRoutes,
  ...worldRoutes,
  {
    path: '/:locale/appointments',
    name: 'appointments',
    component: () => import('@/views/Appointments'),
    meta: {
      allowed: true
    }
  },
  {
    path: '/:locale/showroom',
    name: 'appointments-showroom',
    component: () => import('@/views/AppointmentsShowroom'),
    meta: {
      allowed: true
    }
  },
  {
    path: '/:locale/quotes/:id/pdf/:hash',
    name: 'ww.quotes.pdf',
    component: () => import('@/views/world/QuotePdf'),
    meta: {
      allowed: true
    }
  },
  {
    path: '/:locale/quotes/:id/pdf',
    name: 'ww.quotes.pdf',
    component: () => import('@/views/world/QuotePdf'),
    meta: {
      allowed: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.name === 'ww.login') {
    if (store.getters.loggedIn) {
      return next({ name: 'ww.dashboard' })
    } else {
      return next()
    }
  } else if (to.name === 'ww.logout') {
    await store.dispatch('removeToken')
    localStorage.setItem('redirectName', from.name)
    localStorage.setItem('redirectParams', JSON.stringify(from.params))
    return next({ name: 'ww.login', params: { locale: i18n.locale } })
  } else {
    if (to.meta.allowed) {
      return next()
    }

    if (!store.getters.getCurrentUser) {
      if (!store.state.currentUserId || store.state.currentUserId === 'null') {
        localStorage.setItem('redirectName', to.name)
        localStorage.setItem('redirectParams', JSON.stringify(to.params))
        return next({ name: 'ww.login', params: { locale: to.params.locale } })
      } else {
        await store.dispatch('fetchCurrentUser')
      }
    }

    if (to.meta.permissions !== undefined && !UserPermissions.check(to.meta.permissions)) {
      return next({ name: 'ww.dashboard' })
    }

    return next()
  }
})

export default router
