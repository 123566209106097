import { ApiService } from '@/services/admin/api.service'

export const NewsModule = {
  namespaced: true,
  state: {
    news: [],
    total: 0,
    page: 0,
    perPage: 25
  },
  getters: {
    getNews: (state) => state.news
  },
  mutations: {
    setNews (state, payload) {
      state.news = payload.news_items
      state.total = payload.total
    },
    setPagination (state, payload) {
      state.page = payload.page !== undefined ? payload.page : state.page
      state.perPage = payload.perPage !== undefined ? payload.perPage : state.perPage
    }
  },
  actions: {
    async fetchNews ({ state, commit }, payload = {}) {
      commit('setPagination', payload)
      await ApiService.fetchNewsItems(state.page).then(res => {
        commit('setNews', res.data)
      })
    }
  }
}
