import { ApiService } from '@/services/admin/api.service'

export const RolesModule = {
  namespaced: true,
  state: {
    roles: [],
    permissions: [],
    rolesTotal: 0,
    page: 0,
    perPage: 25
  },
  getters: {
    getRoles: (state) => state.roles,
    getPermissions: (state) => state.permissions
  },
  mutations: {
    setRoles (state, payload) {
      state.roles = payload.roles
      state.rolesTotal = payload.total
    },
    setPermissions (state, payload) {
      state.permissions = payload
    },
    setPagination (state, payload) {
      state.page = payload.page !== undefined ? payload.page : state.page
      state.perPage = payload.perPage !== undefined ? payload.perPage : state.perPage
    }
  },
  actions: {
    async fetchRoles ({ state, commit }, payload = {}) {
      commit('setPagination', payload)
      await ApiService.fetchRoles(state.page, state.perPage).then(res => {
        commit('setRoles', res.data)
      })
    },
    async fetchPermissions ({ state, commit }, payload) {
      await ApiService.fetchPermissions().then(res => commit('setPermissions', res.data))
    }
  }
}
