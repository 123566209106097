import mixins from '@/mixins'
import i18n from '@/i18n'

export const iotRoutes = [
  {
    path: '/:locale/iot',
    component: () => import('@/views/iot/Layout'),
    redirect: { name: 'iot.installer' },
    beforeEnter: (to, from, next) => {
      const locale = to.params.locale

      if (!mixins.data().activeLocalesFront[locale]) {
        return next('/nl/iot/installer')
      }

      if (i18n.locale !== locale) {
        i18n.locale = locale
      }

      return next()
    },
    children: [
      {
        path: 'installer',
        name: 'iot.installer',
        component: () => import('@/views/iot/Dashboard')
      },
      {
        path: 'installer/homes',
        name: 'iot.installer.homes',
        component: () => import('@/views/iot/Homes')
      },
      {
        path: 'installer/homes/:home',
        name: 'iot.installer.details',
        component: () => import('@/views/iot/Details')
      },
      {
        path: 'installer/homes/:home/graphs/:device',
        name: 'iot.installer.graphs',
        component: () => import('@/views/iot/Graphs')
      }
    ]
  }
]
