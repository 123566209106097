import { DefaultService } from '../default'

class ApiService extends DefaultService {
  constructor () {
    super(process.env.VUE_APP_IOT)
  }

  getAxiosInstance () {
    return this.axios
  }

  getHome (homeId) {
    return this.axios.get('/homes/' + homeId)
  }

  getHomes () {
    return this.axios.get('/homes')
  }

  createHome (home) {
    return this.axios.post('/homes', home)
  }

  updateHome (homeId, home) {
    return this.axios.post('/homes/' + homeId, home)
  }

  getRooms (homeId) {
    return this.axios.get('/homes/' + homeId + '/rooms')
  }

  createRoom (homeId, room) {
    return this.axios.post('/homes/' + homeId + '/rooms', room)
  }

  getRoomTypes () {
    return this.axios.get('/room-types')
  }

  getDevice (homeId, roomId) {
    return this.axios.get('/homes/' + homeId + '/rooms/' + roomId + '/devices')
  }

  createDevice (homeId, roomId, device) {
    return this.axios.post('/homes/' + homeId + '/rooms/' + roomId + '/devices', device)
  }

  getAverages (homeId, roomId, deviceId, time) {
    return this.axios.get('/homes/' + homeId + '/rooms/' + roomId + '/devices/' + deviceId + '/averages/' + time)
  }

  getStats (homeId, roomId, deviceId) {
    return this.axios.get('/homes/' + homeId + '/rooms/' + roomId + '/devices/' + deviceId + '/stats')
  }

  getLast (homeId, roomId, deviceId) {
    return this.axios.get('/homes/' + homeId + '/rooms/' + roomId + '/devices/' + deviceId + '/last')
  }

  rebootDevice (homeId, roomId, deviceId) {
    return this.axios.post('/homes/' + homeId + '/rooms/' + roomId + '/devices/' + deviceId + '/reboot')
  }

  changeRPM (homeId, roomId, deviceId, payload) {
    return this.axios.post('/homes/' + homeId + '/rooms/' + roomId + '/devices/' + deviceId, payload)
  }
}

const apiService = new ApiService()

export default apiService
export { apiService, apiService as ApiService }
