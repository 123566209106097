import { UserPermissions } from '@/permissions'
import { ApiService } from '@/services/admin/api.service'

export default {
  data: function () {
    return {
      activeLocalesBack: { nl: 'Nederlands', fr: 'Français', en: 'English', sv: 'Svenska' },
      activeLocalesFront: { nl: 'Nederlands', fr: 'Français', en: 'English', sv: 'Svenska' },
      activeLocale: null,
      dealer: null,
      choice: null
    }
  },
  mounted () {
    this.activeLocale = this.$i18n.locale
  },
  methods: {
    getLangCode (item, index) {
      return item.translations[index]?.lang_code || ''
    },
    addTrans (item, lang) {
      item.translations.push({ lang_code: lang })
    },
    hasTrans (item, lang = this.$i18n.locale) {
      return item.translations.find((trans) => trans.lang_code === lang) !== undefined
    },
    removeTrans (item, index) {
      item.translations.splice(index, 1)
    },
    getTranslation: function (item, lang = '', fallback = false) {
      let translation = {}

      if (item && item.translations && item.translations.length > 0) {
        item.translations.forEach((obj) => {
          if (lang !== '') {
            if (obj.lang_code === lang) {
              translation = obj
            }
          } else {
            if (obj.lang_code === this.$i18n.locale) {
              translation = obj
            }
          }
        })

        if (!fallback && Object.keys(translation).length === 0) {
          translation = this.getTranslation(item, 'nl', true)
        }
      }

      return translation
    },
    async setDealer () {
      const dealerId = localStorage.getItem('chosenDealerId')

      this.dealer = null
      this.choice = null

      if (dealerId) {
        await ApiService.fetchDealer(dealerId).then(res => {
          if (res.status === 200) {
            this.dealer = this.choice = res.data
          }
        })

        if (!this.dealer.loyalty) {
          await ApiService.fetchDealerLoyalty(dealerId).then(res => {
            if (res.status === 200) {
              this.$set(this.dealer, 'loyalty', res.data)
            }
          })
        }

        return
      }

      await this.$store.dispatch('fetchCurrentUserDealers')

      if (!this.checkPermissions(['dealer.view']) && this.currentUserDealers.length === 1) {
        this.dealer = this.choice = this.$store.getters.getCurrentUserDealers[0]

        if (!this.dealer.loyalty) {
          await ApiService.fetchDealerLoyalty(this.dealer.id).then(res => {
            if (res.status === 200) {
              this.$set(this.dealer, 'loyalty', res.data)
            }
          })
        }

        localStorage.setItem('chosenDealerId', this.dealer.id)
        return
      }

      this.dealer = null

      if (this.currentUserDealers.length > 1 && this.$route.name !== 'ww.dashboard') {
        await this.$router.push({ name: 'ww.dashboard' })
      }
    },
    checkPermissions (permissions) {
      return UserPermissions.check(permissions)
    }
  },
  computed: {
    hasLoyaltyAccess () {
      // As requested by Wim on 30/06/2022 9h20
      // Only allow role 1 to see loyalty
      // return (this.dealer && (this.currentUser.roles.some((role) => [1].includes(role.id)) || this.currentUser.is_superuser) && !this.dealer.project_dealer)

      // As requested by Wim/Hanne on 03/02/2023
      // Revert loyalty access restriction
      return (this.dealer && (this.currentUser.roles.some((role) => [1, 2, 6, 7].includes(role.id)) || this.currentUser.is_superuser) && !this.dealer.project_dealer)
    },
    currentUser () {
      return this.$store.getters.getCurrentUser
    },
    currentUserDealers () {
      return this.$store.getters.getCurrentUserDealers
    }
  }
}
