import { ApiService } from '@/services/admin/api.service'

export const DealersModule = {
  namespaced: true,
  state: {
    dealer: null,
    dealers: [],
    total: 0,
    page: 0,
    perPage: 25,
    sortDir: 'asc',
    sortBy: 'dealer.id'
  },
  getters: {
    getDealer: (state) => state.dealer,
    getDealers: (state) => state.dealers
  },
  mutations: {
    setDealers (state, payload) {
      state.dealers = payload.dealers
      state.total = payload.total
    },
    setDealer (state, payload) {
      state.dealer = payload
    },
    setPagination (state, payload) {
      state.page = payload.page !== undefined ? payload.page : state.page
      state.perPage = payload.perPage !== undefined ? payload.perPage : state.perPage
    },
    setSortOrder (state, payload) {
      state.sortBy = payload.sortBy !== undefined ? payload.sortBy : state.sortBy
      state.sortDir = payload.sortDir !== undefined ? payload.sortDir : state.sortDir
    }
  },
  actions: {
    async fetchDealers ({ state, commit }, payload = {}) {
      commit('setPagination', payload)
      commit('setSortOrder', payload)
      await ApiService.fetchDealers(state.page, state.perPage, payload.search, state.sortBy, state.sortDir, payload.withoutUsers).then(res => {
        commit('setDealers', res.data)
      })
    },
    async fetchDealer ({ state, commit }, payload = {}) {
      await ApiService.fetchDealer(payload).then(res => {
        commit('setDealer', res.data)
      })
    }
  }
}
