import { ApiService } from '@/services/iot/api.service'

export const RoomsModule = {
  namespaced: true,
  state: {
    rooms: [],
    roomTypes: []
  },
  getters: {
    getRooms: (state) => state.rooms,
    getRoomTypes: (state) => state.roomTypes
  },
  mutations: {
    setRooms (state, payload) {
      state.rooms = payload
    },
    createRoom (state, payload) {
      state.rooms.push(payload)
    },
    setRoomTypes (state, payload) {
      state.roomTypes = payload
    },
    addDevice (state, payload) {
      const room = state.rooms.find(room => room.id === payload.roomId)
      room.devices.push(payload.data)
    }
  },
  actions: {
    async fetchRooms ({ commit }, payload = {}) {
      await ApiService.getRooms(payload.homeId).then(res => {
        commit('setRooms', res.data)
      })
    },
    async createRoom ({ commit }, payload = {}) {
      await ApiService.createRoom(payload.homeId, payload.room).then(res => {
        commit('createRoom', res.data)
      })
    },
    async fetchRoomTypes ({ commit }) {
      await ApiService.getRoomTypes().then(res => {
        commit('setRoomTypes', res.data)
      })
    }
  }
}
